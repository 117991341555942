<template>
    <v-form class="mt-4">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="70vh">
            <v-container class="pa-0" style="height: 70vh">
                <v-row>
                    <v-col cols="12" class="d-flex">
                        <p class="text-h5 font-weight-semibold black--text">{{ $t('Monitoring') }}</p>
                        <v-radio-group
                            v-model="selectedMonitoringType"
                            :row="true"
                            class="mt-0 ml-6"
                            :disabled="disabled">
                            <v-radio class="mx-2" v-for="type in monitoringType" :key="type.value" :value="type.value">
                                <template v-slot:label>
                                    <span class="black--text" style="font-size: 1.2rem">{{ $t(type.name) }}</span>
                                </template></v-radio
                            >
                        </v-radio-group>
                    </v-col>
                </v-row>

                <div v-if="selectedMonitoringType == 'general'" class="px-2">
                    <ValidationObserver v-slot="{ handleSubmit }" :disabled="true">
                        <v-form class="mt-15" @submit.prevent="handleSubmit(onAddSpot)">
                            <v-row>
                                <v-col cols="12" md="4" lg="2">
                                    <ValidationProvider :name="$t('SpotTypes')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            filled
                                            shaped
                                            :items="spotTypes"
                                            :label="$t('SpotTypes')"
                                            item-text="name"
                                            item-value="value"
                                            name="spotType"
                                            v-model="selectedSpotType"
                                            :disabled="disabled"></v-select>
                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="4" lg="2">
                                    <ValidationProvider
                                        :name="$t('Quantity')"
                                        :rules="spotTypes ? 'required|min_value:1' : 'min_value:1'"
                                        v-slot="{ errors }">
                                        <v-text-field
                                            filled
                                            shaped
                                            :label="$t('Quantity')"
                                            hide-details="auto"
                                            type="number"
                                            required
                                            v-model="selectedSpotQuantity"
                                            name="quantity"
                                            :disabled="disabled"></v-text-field>
                                        <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="2" class="mt-1 mx-3">
                                    <v-row class="mt-0">
                                        <v-btn
                                            color="primary"
                                            type="submit"
                                            block
                                            :min-height="50"
                                            :disabled="disabled">
                                            <span>{{ $t('Buttons.Add') }}</span>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </ValidationObserver>

                    <v-row :class="$vuetify.breakpoint.smAndDown ? 'mt-8' : ''">
                        <v-col cols="12" md="8" lg="6">
                            <v-sheet v-for="(spot, index) in createdSpots" :key="index">
                                <v-sheet class="px-3 py-5 mb-8 has-radius" :elevation="6" v-if="spot.quantity > 0">
                                    <v-row class="px-6">
                                        <v-col cols="4" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-2 font-weight-medium">{{
                                                $t(spot.type.charAt(0).toUpperCase() + spot.type.slice(1))
                                            }}</span>
                                        </v-col>
                                        <v-col cols="4" class="text-center d-flex align-center justify-center">
                                            <span
                                                class="black--text text-body-2 font-weight-medium"
                                                v-if="!spot.isEditting"
                                                >{{ spot.quantity }} {{ $tc('Spots', spot.quantity) }}</span
                                            >
                                            <ValidationProvider
                                                :name="$t('Quantity')"
                                                rules="min_value:1|required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field
                                                    type="number"
                                                    v-model="spot.quantity"
                                                    required></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="4" class="text-center d-flex align-center justify-center">
                                            <v-dialog transition="dialog-top-transition" max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                        <v-icon>
                                                            {{ mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <v-img
                                                        class="mx-auto trashcan"
                                                        width="80"
                                                        src="@/assets/icons/delete.png"></v-img>
                                                    <v-card>
                                                        <v-card-text class="text-center">
                                                            <p class="title font-weight-semibold black--text mt-12">
                                                                {{ $t('Alerts.Sure') }}
                                                            </p>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            <p class="body-2">
                                                                {{ $t('Alerts.SureDeleteGeneralLot') }}
                                                                <strong class="black--text">{{
                                                                    $t(
                                                                        spot.type.charAt(0).toUpperCase() +
                                                                            spot.type.slice(1),
                                                                    )
                                                                }}</strong>
                                                                ?<br />
                                                                {{ $t('Alerts.Irreversible') }}
                                                            </p>
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end mt-4">
                                                            <v-btn
                                                                color="error"
                                                                class="px-5"
                                                                @click="onDeleteGeneralSpot(index)"
                                                                >{{ $t('Buttons.Delete') }}</v-btn
                                                            >
                                                            <v-btn text @click="dialog.value = false">{{
                                                                $t('Buttons.Cancel')
                                                            }}</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </template>
                                            </v-dialog>
                                            <v-btn
                                                elevation="0"
                                                class="ml-2"
                                                :disabled="disabled"
                                                @click="onActivateEditGenerealSpot(index)"
                                                v-if="!spot.isEditting">
                                                <v-icon color="grey">
                                                    {{ mdiFileDocumentEditOutline }}
                                                </v-icon>
                                            </v-btn>

                                            <v-btn elevation="0" v-else @click="onEditGenerealSpot(index)">
                                                <v-icon color="grey">
                                                    {{ mdiCheck }}
                                                </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>

                <div v-else class="mt-8">
                    <div v-if="!showStructure">
                        <p class="text-h5 font-weight-medium black--text">{{ $t('ParkContent') }}</p>
                        <v-checkbox
                            class="mt-0"
                            v-for="parkOption in parkOptions"
                            :key="parkOption.value"
                            v-model="selectedParkOptions"
                            :value="parkOption.value"
                            :disabled="parkOption.value == 'spots' || disabled ? true : false">
                            <template v-slot:label>
                                <span class="black--text" style="font-size: 1.1rem">{{ parkOption.name }}</span>
                            </template></v-checkbox
                        >
                    </div>

                    <StructureSelected
                        @getStructure="getStructure"
                        v-if="showStructure"
                        :structure="selectedParkOptions"
                        :createdStructure="structure"
                        :disabled="disabled" />
                </div>
            </v-container>
        </v-sheet>

        <v-app-bar
            v-if="selectedMonitoringType == 'individual'"
            height="80"
            bottom
            absolute
            color="white"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    class="mx-3"
                    @click="showStructure = true"
                    v-if="!showStructure"
                    :disabled="selectedParkOptions.length == 1 ? true : false">
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Continue') }}</span>
                </v-btn>
            </v-container>
        </v-app-bar>
    </v-form>
</template>

<script>
    import { mdiClose, mdiAlertCircleOutline, mdiTrashCanOutline, mdiFileDocumentEditOutline, mdiCheck } from '@mdi/js';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import StructureSelected from './StructureSelected';
    export default {
        /*   props: {
            _floors: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        model: {
            prop: '_floors',
            event: 'setFloors',
        }, */

        props: ['structure', 'parkType', 'disabled', 'generalLots'],

        components: {
            ValidationProvider,
            ValidationObserver,
            StructureSelected,
        },
        setup() {
            return {
                mdiClose,
                mdiAlertCircleOutline,
                mdiTrashCanOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,

                monitoringType: [
                    { name: 'General', value: 'general' },
                    { name: 'Individual', value: 'individual' },
                ],

                spotTypes: [
                    { name: '', value: 'normalSpot' },
                    { name: '', value: 'pregnantSpot' },
                    { name: '', value: 'disabledSpot' },
                ],

                parkOptions: [
                    {
                        name: '',
                        value: 'spots',
                    },
                    {
                        name: '',
                        value: 'floors',
                    },
                    {
                        name: '',
                        value: 'sections',
                    },
                ],
            };
        },
        created() {
            /*  this.floors = this._floors; */
            if (this.$route.params.id != 'add') {
                this.addPark = false;
                this.isDisabled = true;
                console.log(this.structure);

                this.selectedMonitoringType = this.parkType;
                console.log(this.selectedMonitoringType);

                if (this.selectedMonitoringType == 'general') {
                    console.log(this.generalLots);

                    Object.entries(this.generalLots).forEach(([key, value]) => {
                        if (key == 'normalCapacity') {
                            this.createdSpots.push({
                                type: 'normalSpot',
                                quantity: value,
                                isEditting: false,
                            });
                        } else if (key === 'pregnantCapacity') {
                            this.createdSpots.push({
                                type: 'pregnantSpot',
                                quantity: value,
                                isEditting: false,
                            });
                        } else {
                            this.createdSpots.push({
                                type: 'disabledSpot',
                                quantity: value,
                                isEditting: false,
                            });
                        }
                    });
                } else {
                    let floor = this.structure.find((item) => item.type == 'floor' && item.showOnEdit);
                    console.log(floor);
                    if (floor) {
                        this.selectedParkOptions.push('floors');
                    }

                    let section = this.structure.find((item) => item.type == 'section');
                    console.log(section);

                    if (section) {
                        this.selectedParkOptions.push('sections');
                    }

                    if (this.selectedMonitoringType && this.selectedParkOptions.length > 1) {
                        this.showStructure = true;
                    } else {
                        this.selectedMonitoringType = 'general';
                        this.showStructure = false;
                    }
                }
            } else {
                this.addPark = true;
                this.isDisabled = false;
            }

            this.spotTypes[0].name = this.$t('NormalSpot');
            this.spotTypes[1].name = this.$t('PregnantSpot');
            this.spotTypes[2].name = this.$t('DisabledSpot');

            this.parkOptions[0].name = this.$tc('Spots', 2);
            this.parkOptions[1].name = this.$tc('Floors', 2);
            this.parkOptions[2].name = this.$tc('Sections', 2);
        },
        data() {
            return {
                selectedMonitoringType: 'general',
                spots: [],
                selectedSpotType: null,
                selectedSpotQuantity: 0,
                createdSpots: [],
                selectedParkOptions: ['spots'],
                showStructure: false,
            };
        },
        watch: {
            floors: {
                handler(newValue) {
                    this.$emit('setFloors', newValue);
                },
                deep: true,
            },
            _floors: {
                handler(newValue) {
                    this.floors = newValue;
                },
                deep: true,
            },
        },
        methods: {
            onAddSpot() {
                const spotExist = this.createdSpots.find((spot) => spot.type === this.selectedSpotType);

                if (spotExist) {
                    spotExist.quantity = parseInt(spotExist.quantity) + parseInt(this.selectedSpotQuantity);
                } else {
                    this.createdSpots.push({
                        type: this.selectedSpotType,
                        quantity: this.selectedSpotQuantity,
                        isEditting: false,
                    });
                }
                console.log(this.createdSpots);
                this.getStructure(this.createdSpots, 'general', []);
            },

            getStructure(structure, type, selectedStructure) {
                if (this.$route.params.id != 'add') {
                    console.log(this.selectedMonitoringType);
                    if (this.selectedMonitoringType == 'general') {
                        this.$emit('getFinalStructure', structure, this.selectedMonitoringType, []);
                    } else this.$emit('getFinalStructure', structure, this.selectedMonitoringType, selectedStructure);
                } else this.$emit('getFinalStructure', structure, type, selectedStructure);
            },

            onDeleteGeneralSpot(index) {
                this.createdSpots[index].quantity = 0;
                this.getStructure(this.createdSpots, 'general', []);
            },

            onActivateEditGenerealSpot(index) {
                this.createdSpots[index].isEditting = true;
            },

            onEditGenerealSpot(index) {
                console.log(this.createdSpots);
                this.getStructure(this.createdSpots, 'general', []);
                this.createdSpots[index].isEditting = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .has-radius {
        border-radius: 16px;
    }

    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }

    .row {
        width: 100% !important;
    }
</style>
