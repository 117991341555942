<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container style="height: 100vh">
                        <v-tabs center-active>
                            <v-tab>{{ $t('ParkProfile') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('ParkStructure') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Managers') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col
                                        class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="200">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Name')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        v-model="form.name"
                                                        :disabled="isDisabled"
                                                        :label="$t('Form.Name')"
                                                        required
                                                        filled
                                                        hide-details="auto"
                                                        name="name"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Phone')"
                                                    rules="required|mobile-phone"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        v-model="form.phone"
                                                        :label="$t('Form.Phone')"
                                                        name="tel"
                                                        hide-details="auto"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Email')"
                                                    rules="required|email"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        v-model="form.email"
                                                        :label="$t('Form.Email')"
                                                        name="email"
                                                        hide-details="auto"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex align-center">
                                                    <v-select
                                                        :disabled="isDisabled"
                                                        :items="['09:00', '24:00']"
                                                        filled
                                                        shaped
                                                        :label="$t('Form.Schedule')"></v-select>
                                                    <p class="px-4">às</p>
                                                    <v-select
                                                        :disabled="isDisabled"
                                                        :items="['09:00', '24:00']"
                                                        filled
                                                        shaped
                                                        :label="$t('Form.Schedule')"></v-select></div
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        class="form-pic d-none d-md-flex d-lg-flex d-xl-flex justify-end"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="150">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>

                                    <v-col cols="12" md="9">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Cost')"
                                                    rules="required|min_value:0"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :min="0"
                                                        :error="errors[0] ? true : false"
                                                        hide-details="auto"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        type="number"
                                                        v-model="form.price"
                                                        :label="$t('Form.Cost')"
                                                        suffix="€/hora"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-select :disabled="isDisabled" filled label="Sensores"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <ValidationProvider
                                            :name="$t('Form.PostalCode')"
                                            rules="required|postal-code"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                @change="getAddresses()"
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                v-model="form.zipCode"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('Form.PostalCode')"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <ValidationProvider
                                            :name="$t('Form.Address')"
                                            rules="required"
                                            v-slot="{ errors }">
                                            <v-combobox
                                                :items="addresses"
                                                item-value="Artéria"
                                                item-text="Artéria"
                                                v-model="form.address"
                                                hide-details
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                hide-no-data
                                                :label="$t('Form.Address')"
                                                required></v-combobox>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Country')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getDistricts"
                                                v-model="selectedCountry"
                                                item-value="id"
                                                item-text="name"
                                                :items="countries"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                name="country"
                                                :label="$t('Country')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('District')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getCounties"
                                                :items="districts"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedDistrict"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('District')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('County')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getParishes"
                                                :items="counties"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedCounty"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('County')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Parish')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :items="parishes"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedParish"
                                                @change="getParishCoords"
                                                :disabled="isDisabled"
                                                filled
                                                :label="$t('Parish')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <ValidationProvider
                                            :name="$t('Form.LatLng')"
                                            rules="required|lat-lgn"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                v-model="coordinatesField"
                                                filled
                                                shaped
                                                :label="$t('Form.LatLng')"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('ParkType')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :items="['indoor', 'outdoor']"
                                                v-model="selectedParkType"
                                                :disabled="isDisabled"
                                                filled
                                                :label="$t('ParkType')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('ParkType')" rules="required" v-slot="{ errors }">
                                            <v-checkbox
                                                v-model="form.isActive"
                                                :label="$t('ActivePark')"
                                                :value="true"
                                                hide-details
                                                :disabled="isDisabled"></v-checkbox>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <GmapMap
                                            :center="place.coordinates"
                                            :zoom="16"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 500px">
                                            <GmapMarker
                                                :position="place.coordinates"
                                                :clickable="true"
                                                :draggable="false" />
                                        </GmapMap>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <ParkStructure
                                    v-model="form.sections"
                                    :disabled="isDisabled"
                                    @getFinalStructure="getFinalStructure"
                                    :structure="form.sections"
                                    :parkType="form.monitoring"
                                    :generalLots="generalLots"></ParkStructure>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table
                                    :show-select="!isDisabled"
                                    calculate-widths
                                    v-model="selectedUsers"
                                    :headers="tableColumns"
                                    :items="selectedUsers.length > 0 ? orderBySelectedUsers : managers"
                                    :loading="isLoadingTable"
                                    :items-per-page="5"
                                    item-key="id"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 20],
                                        'items-per-page-text': $t('RowsPerPage'),
                                    }"
                                    :server-items-length="totalUserListTable"
                                    class="mt-15"
                                    :header-props="{ 'sort-by-text': $t('SortBy') }">
                                    <template v-slot:top>
                                        <v-text-field
                                            v-model="searchUser"
                                            :placeholder="$t('Search')"
                                            outlined
                                            hide-details
                                            dense
                                            expanded
                                            :prepend-inner-icon="mdiMagnify"
                                            class="mb-6"
                                            @click:prepend-inner="searchUsers()"
                                            @keydown.enter.prevent="searchUsers" />
                                    </template>

                                    <!-- name -->
                                    <template #[`item.name`]="{ item }">
                                        <v-avatar
                                            :color="item.avatar ? '' : 'primary'"
                                            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                                            size="32">
                                            <v-img
                                                v-if="resolveUserPicture(item.picture)"
                                                :src="resolveUserPicture(item.picture)"></v-img>
                                            <span v-else class="caption font-weight-medium">{{
                                                avatarText(item.name)
                                            }}</span>
                                        </v-avatar>
                                        <span class="ftext--primary font-weight-semibold text-truncate ms-3">{{
                                            item.name
                                        }}</span>
                                    </template>

                                    <!-- email -->
                                    <template #[`item.email`]="{ item }">
                                        <span class="font-weight-medium text--primary">{{ item.email }}</span>
                                    </template>

                                    <!-- role -->
                                    <template #[`item.role`]="{ item }">
                                        <span class="text-capitalize font-weight-medium text--primary">{{
                                            item.role
                                        }}</span>
                                    </template>
                                    <!-- parks -->
                                    <template v-slot:item.parkingLots="{ item }">
                                        <span v-if="item.parkingLots.length > 0">{{ item.parkingLots[0].name }}</span>
                                        <v-tooltip bottom v-if="item.parkingLots.length > 1">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    class="info--text"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    style="cursor: default"
                                                    >{{ limitParkText(item.parkingLots) }}
                                                    {{ $tc('ParkTooltip', item.parkingLots.slice(1).length) }}</span
                                                >
                                            </template>
                                            <span>{{
                                                item.parkingLots
                                                    .slice(1)
                                                    .map((entry) => entry.name)
                                                    .join(', ')
                                            }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addPark">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('ParkProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('ParkProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddPark') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddPark') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addPark">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDelete') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deletePark" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR PARQUES-->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import useParkList from '../park-list/useParkList';
    import useUsersList from '../../user/user-list/useUsersList';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import { getLocations } from '@/api/location';
    import _ from 'lodash';
    import ParkStructure from '@/views/parks/park-structure/ParkStructure.vue';
    import { getUsers } from '@/api/user';
    import { addSections, updateSections, deleteSections } from '../../../api/sections.js';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getAddress } from '@/api/address';
    import { addLots, updateLots } from '@/api/lots';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            price: 0,
            address: '',
            zipCode: '',
            pictures: [],
            managers: [],
            sections: [],
            phone: '',
            entity: null,
            schedules: [],
            sensorType: 'individual',
            observations: '',
            longitude: 0,
            latitude: 0,
            freeLots: 0,
            totalCapacity: 0,
            pregnantCapacity: 0,
            handicappedCapacity: 0,
            chargerCapacity: 0,
            monitoringType: null,
            isActive: true,
        },

        isLoadingTable: true,
        structure: [],
    });

    export default {
        components: {
            ParkStructure,
            ValidationProvider,
            ValidationObserver,
            Map,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                //   costRules: [(v) => !!v || 'Cost is required', (v) => (v && v <= 0) || 'Não pode ser menor que 0'],
                emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],

                file: null,
                countries: [],
                districts: [],
                counties: [],
                parishes: [],
                selectedUsers: [],
                managers: [],
                selectedCountry: null,
                selectedDistrict: null,
                selectedCounty: null,
                selectedParish: null,
                selectedParishLat: null,
                selectedParishLng: null,
                addPark: false,
                dialog: false,
                place: {
                    coordinates: {
                        lat: 0,
                        lng: 0,
                    },
                },
                sectionsToDelete: [],
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                coordinatesField: null,
                addresses: [],
                searchUser: '',
                selectedParkType: null,
                selectedStructure: null,
                generalLots: {},
            };
        },
        async created() {
            if (this.$route.params.id != 'add') {
                this.addPark = false;
                this.isDisabled = true;
                await this.getPark();
            } else {
                this.addPark = true;
                this.isDisabled = false;
            }
            await this.getUsersName();
            await this.getCountries();
        },

        setup() {
            const { resolveParkPictureMax, limitParkText } = useParkList();
            const { resolveUserPicture, tableColumns, totalUserListTable } = useUsersList();

            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                resolveParkPictureMax,
                avatarText,
                resolveUserPicture,
                tableColumns,
                totalUserListTable,
                limitParkText,
            };
        },
        computed: {
            getPic() {
                return this.resolveParkPictureMax(this.form.pictures);
            },

            orderBySelectedUsers() {
                for (let index = 0; index < this.selectedUsers.length; index++) {
                    this.managers.unshift(this.selectedUsers[index]);

                    //remove duplicates from array
                    this.managers = this.managers.filter(
                        (user, index, self) => index === self.findIndex((i) => i.id === user.id),
                    );
                }
                return this.managers;
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },
            getPark() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-park/fetchPark', this.$route.params.id)
                    .then((response) => {
                        console.log(response.data.data);
                        this.form = response.data.data;
                        this.selectedCountry = _.get(this.form, 'country.id');
                        this.selectedDistrict = _.get(this.form, 'district.id');
                        this.selectedCounty = _.get(this.form, 'county.id');
                        this.selectedParish = _.get(this.form, 'parish.id');

                        let managers = _.get(this.form, 'managers');

                        this.selectedUsers = managers ? managers : [];
                        this.getDistricts();
                        this.getCounties();
                        this.getParishes();
                        this.getAddresses();
                        this.coordinatesField = this.form.latitude + ', ' + this.form.longitude;
                        this.place.coordinates.lat = this.form.latitude;
                        this.place.coordinates.lng = this.form.longitude;
                        this.selectedParkType = this.form.type;
                        this.generalLots = {
                            normalCapacity: this.form.normalCapacity,
                            pregnantCapacity: this.form.pregnantCapacity,
                            handicappedCapacity: this.form.handicappedCapacity,
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onSubmit() {
                if (this.addPark) {
                    await this.onAddPark();
                } else await this.onUpdatePark();

                setTimeout(() => {
                    this.$router.push({ name: 'ParkList' });
                }, 1500);
            },
            async onAddPark() {
                this.isLoading = true;

                let selectedUsersId = this.selectedUsers.map((user) => {
                    return user.id;
                });

                let parkData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto parkData (colonar objeto) é o que faz a função assing do lodash
                parkData.country = this.selectedCountry;
                parkData.district = this.selectedDistrict;
                parkData.county = this.selectedCounty;
                parkData.parish = this.selectedParish;
                parkData.managers = selectedUsersId;
                parkData.sections = this.structure;
                parkData.type = this.selectedParkType;

                console.log(parkData.sections);

                if (this.monitoringType == 'general') {
                    parkData.monitoring = this.monitoringType;

                    parkData.sections.forEach((element) => {
                        if (element.type == 'normalSpot') {
                            parkData.normalCapacity = element.quantity;
                        } else if (element.type == 'pregnantSpot') {
                            parkData.pregnantCapacity = element.quantity;
                        } else if (element.type == 'disabledSpot') {
                            parkData.handicappedCapacity = element.quantity;
                        }

                        parkData.totalCapacity += parseInt(element.quantity);
                    });
                } else {
                    parkData.monitoring = this.monitoringType;
                    parkData.sections.forEach((element) => {
                        parkData.totalCapacity += parseInt(element.floorTotalCapacity);
                    });
                }

                await this.$store
                    .dispatch('app-park/addPark', parkData)
                    .then(async (response) => {
                        this.createSuccess = true;
                        console.log(response);
                        let parkId = response.data.data.id;
                        let allFloors = [];
                        let floorId = null;

                        if (this.monitoringType == 'individual') {
                            for (let i = 0; i < parkData.sections.length; i++) {
                                console.log(parkData.sections);
                                parkData.sections[i].parkingLot = parkId;
                                parkData.sections[i].type = 'floor';
                                parkData.sections[i].capacity = parkData.sections[i].floorTotalCapacity;

                                for (let section of parkData.sections[i].spots) {
                                    parkData.sections[i].name = section.floor.toString();
                                }

                                if (!allFloors.includes(parkData.sections[i].name)) {
                                    allFloors.push(parkData.sections[i].name);

                                    let floor = _.assign({}, parkData.sections[i]);

                                    if (this.selectedStructure.includes('floors')) {
                                        floor.showOnEdit = true;
                                    } else floor.showOnEdit = false;

                                    await addSections(floor)
                                        .then(async (response) => {
                                            floorId = response.data.data.id;

                                            if (floor.spots[0].section) {
                                                let section = _.assign({}, parkData.sections[i]);
                                                section.name = section.spots[0].section.toString();
                                                section.parent = floorId;
                                                section.type = 'section';
                                                section.capacity = parkData.sections[i].spots.length;

                                                await addSections(section)
                                                    .then(async (response) => {
                                                        console.log(response);

                                                        for (let spot of parkData.sections[i].spots) {
                                                            if (
                                                                spot.floor == parkData.sections[i].name &&
                                                                spot.section == response.data.data.name
                                                            ) {
                                                                let lot = _.assign({}, spot);
                                                                lot.isFree = true;
                                                                lot.bookings = [];
                                                                lot.parkingLot = parkId;
                                                                lot.sensorId = lot.sensor;
                                                                lot.name = lot.name.toString();
                                                                lot.section = response.data.data.id;

                                                                await addLots(lot)
                                                                    .then(async (response) => {
                                                                        console.log(response);
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        this.isError = true;
                                                                    });
                                                            }
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.isError = true;
                                                    });
                                            } else {
                                                for (let spot of parkData.sections[i].spots) {
                                                    if (spot.floor == parkData.sections[i].name) {
                                                        let lot = _.assign({}, spot);
                                                        lot.isFree = true;
                                                        lot.bookings = [];
                                                        lot.parkingLot = parkId;
                                                        lot.sensorId = lot.sensor;
                                                        lot.name = lot.name.toString();
                                                        lot.section = floorId;

                                                        await addLots(lot)
                                                            .then(async (response) => {
                                                                console.log(response);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.isError = true;
                                                            });
                                                    }
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.isError = true;
                                        });
                                } else {
                                    let section = _.assign({}, parkData.sections[i]);
                                    section.name = section.spots[0].section.toString();
                                    section.parent = floorId;
                                    section.type = 'section';
                                    section.capacity = parkData.sections[i].spots.length;

                                    await addSections(section)
                                        .then(async (response) => {
                                            for (let spot of parkData.sections[i].spots) {
                                                if (
                                                    spot.floor == parkData.sections[i].floor &&
                                                    spot.section == response.data.data.name
                                                ) {
                                                    let lot = _.assign({}, spot);
                                                    lot.isFree = true;
                                                    lot.bookings = [];
                                                    lot.parkingLot = parkId;
                                                    lot.sensorId = lot.sensor;
                                                    lot.name = lot.name.toString();
                                                    lot.section = response.data.data.id;

                                                    await addLots(lot)
                                                        .then(async (response) => {
                                                            console.log(response);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.isError = true;
                                                        });
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.isError = true;
                                        });
                                }
                            }
                        }
                    })

                    .catch((error) => {
                        this.isError = true;
                        console.log(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },
            onSectionToDelete(id) {
                this.sectionsToDelete.push(id);
            },
            async onUpdatePark() {
                this.isLoading = true;

                let selectedUsersId = this.selectedUsers.map((user) => {
                    return user.id;
                });

                console.log(this.structure);
                console.log(this.selectedParkType);
                console.log(this.monitoringType);

                let parkData = _.assign({}, this.form);
                parkData.country = this.selectedCountry;
                parkData.district = this.selectedDistrict;
                parkData.county = this.selectedCounty;
                parkData.parish = this.selectedParish;
                parkData.managers = selectedUsersId;

                // verify if the park is a general park
                if (this.monitoringType == 'general') {
                    parkData.monitoring = this.monitoringType;

                    this.structure.forEach((element) => {
                        if (element.type == 'normalSpot') {
                            parkData.normalCapacity = element.quantity;
                            console.log(element.quantity);
                        } else if (element.type == 'pregnantSpot') {
                            parkData.pregnantCapacity = element.quantity;
                        } else if (element.type == 'disabledSpot') {
                            parkData.handicappedCapacity = element.quantity;
                        }

                        parkData.totalCapacity += parseInt(element.quantity);
                    });
                } else {
                    parkData.monitoring = this.monitoringType;
                    this.structure.forEach((element) => {
                        parkData.totalCapacity += parseInt(element.floorTotalCapacity);
                    });
                }

                parkData.sections = [];

                console.log(parkData);

                //update park
                await this.$store
                    .dispatch('app-park/updatePark', parkData)

                    .then(async (response) => {
                        console.log(parkData);
                        if (this.structure.length > 0) {
                            parkData.sections = this.structure;
                        } else {
                            parkData.sections = [];
                        }
                        this.updateSuccess = true;

                        let parkId = response.data.data.id;
                        let allFloors = [];
                        let floorId = null;

                        if (this.structure.length > 0) {
                            if (this.monitoringType == 'individual') {
                                for (let i = 0; i < parkData.sections.length; i++) {
                                    console.log(parkData.sections[i]);
                                    let floor = {};

                                    // create floor object
                                    if (parkData.sections[i].floor) {
                                        floor = {
                                            name: parkData.sections[i].floor,
                                            type: 'floor',
                                            parkingLot: parkId,
                                            capacity: parkData.sections[i].floorTotalCapacity,
                                            id: parkData.sections[i].floorId,
                                            spots: parkData.sections[i].spots,
                                        };
                                    } else {
                                        floor = {
                                            name: parkData.sections[i].name,
                                            type: 'floor',
                                            parkingLot: parkId,
                                            capacity: parkData.sections[i].quantity,
                                            spots: parkData.sections[i].spots,
                                            id: parkData.sections[i].id,
                                        };
                                    }

                                    console.log(floor);

                                    console.log(allFloors);

                                    let sectionName = null;
                                    let sectionId = null;

                                    if (this.selectedStructure.includes('floors')) {
                                        floor.showOnEdit = true;
                                    } else floor.showOnEdit = false;

                                    //verify if the floor was already updated
                                    if (!allFloors.includes(parkData.sections[i].floor)) {
                                        if (parkData.sections[i].floor) {
                                            allFloors.push(parkData.sections[i].floor);
                                        }

                                        if (floor.id && floor.id !== undefined) {
                                            await updateSections(floor.id, floor)
                                                .then(async (response) => {
                                                    floorId = response.data.data.id;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.isError = true;
                                                });
                                        } else {
                                            await addSections(floor)
                                                .then(async (response) => {
                                                    floorId = response.data.data.id;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.isError = true;
                                                });
                                        }

                                        // verify if floor has sections
                                        /*   if (floor.spots.length > 0) { */
                                        /* if (floor.spots[0].section && floor.spots[0].section !== undefined) */
                                        if (this.selectedStructure.includes('sections')) {
                                            let section = _.assign({}, parkData.sections[i]);
                                            section.name = section.name.toString();
                                            section.parent = floorId;
                                            section.type = 'section';
                                            section.capacity = parkData.sections[i].spots.length;
                                            section.parkingLot = parkId;

                                            if (section.id) {
                                                await updateSections(section.id, section)
                                                    .then(async (response) => {
                                                        sectionName = response.data.data.name;
                                                        sectionId = response.data.data.id;
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.isError = true;
                                                    });
                                            } else {
                                                await addSections(section)
                                                    .then(async (response) => {
                                                        console.log(response);
                                                        sectionName = response.data.data.name;
                                                        sectionId = response.data.data.id;
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.isError = true;
                                                    });
                                            }

                                            for (let spot of parkData.sections[i].spots) {
                                                if (
                                                    spot.floor == parkData.sections[i].floor &&
                                                    spot.section == sectionName
                                                ) {
                                                    let lot = _.assign({}, spot);
                                                    lot.isFree = true;
                                                    lot.bookings = [];
                                                    lot.parkingLot = parkId;
                                                    lot.sensorId = lot.sensor;
                                                    lot.name = lot.name.toString();
                                                    lot.section = sectionId;

                                                    if (lot.id) {
                                                        await updateLots(lot.id, lot)
                                                            .then(async (response) => {
                                                                console.log(response);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.isError = true;
                                                            });
                                                    } else {
                                                        await addLots(lot)
                                                            .then(async (response) => {
                                                                console.log(response);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.isError = true;
                                                            });
                                                    }
                                                }
                                            }
                                        } else {
                                            for (let spot of parkData.sections[i].spots) {
                                                if (spot.floor == parkData.sections[i].name) {
                                                    let lot = _.assign({}, spot);
                                                    lot.isFree = true;
                                                    lot.bookings = [];
                                                    lot.parkingLot = parkId;
                                                    lot.sensorId = lot.sensor;
                                                    lot.name = lot.name.toString();
                                                    lot.section = floorId;

                                                    // if lot id exists, update lot
                                                    if (lot.id) {
                                                        await updateLots(lot.id, lot)
                                                            .then(async (response) => {
                                                                console.log(response);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.isError = true;
                                                            });
                                                    } else
                                                        await addLots(lot)
                                                            .then(async (response) => {
                                                                console.log(response);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.isError = true;
                                                            });
                                                }
                                            }
                                        }
                                        /*  } */
                                    } else {
                                        let section = _.assign({}, parkData.sections[i]);
                                        section.name = section.spots[0].section.toString();
                                        section.parent = floorId;
                                        section.type = 'section';
                                        section.capacity = parkData.sections[i].spots.length;
                                        section.parkingLot = parkId;

                                        if (section.id) {
                                            await updateSections(section.id, section)
                                                .then(async (response) => {
                                                    sectionName = response.data.data.name;
                                                    sectionId = response.data.data.id;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.isError = true;
                                                });
                                        } else {
                                            await addSections(section)
                                                .then(async (response) => {
                                                    console.log(response);
                                                    sectionName = response.data.data.name;
                                                    sectionId = response.data.data.id;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.isError = true;
                                                });
                                        }

                                        for (let spot of parkData.sections[i].spots) {
                                            if (
                                                spot.floor == parkData.sections[i].floor &&
                                                spot.section == sectionName
                                            ) {
                                                let lot = _.assign({}, spot);
                                                lot.isFree = true;
                                                lot.bookings = [];
                                                lot.parkingLot = parkId;
                                                lot.sensorId = lot.sensor;
                                                lot.name = lot.name.toString();
                                                lot.section = sectionId;

                                                // if lot id exists, update lot

                                                if (lot.id) {
                                                    await updateLots(lot.id, lot)
                                                        .then(async (response) => {
                                                            console.log(response);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.isError = true;
                                                        });
                                                } else {
                                                    await addLots(lot)
                                                        .then(async (response) => {
                                                            console.log(response);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.isError = true;
                                                        });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onFileInput() {
                this.isLoading = true;
                await uploadFile(this.file).then((response) => {
                    this.form.pictures = [response.data[0]];
                });
                this.isLoading = false;
            },
            async getCountries() {
                await getLocations({ filters: { level: 0 } }).then((response) => {
                    this.countries = response.data.data;
                });
            },
            getDistricts() {
                this.districts = [];
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedCountry } }).then((response) => {
                    this.districts = response.data.data;
                });
            },
            getCounties() {
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedDistrict } }).then((response) => {
                    this.counties = response.data.data;
                });
            },
            getParishes() {
                getLocations({ filters: { parent: this.selectedCounty } }).then((response) => {
                    this.parishes = response.data.data;
                });
            },
            async getAddresses() {
                this.addresses = [];
                await getAddress(this.form.zipCode).then((response) => {
                    this.addresses = response.data.partes;
                });
            },

            getParishCoords() {
                this.selectedParishLat = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).latitude;
                this.selectedParishLng = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).longitude;
                this.place.coordinates.lat = this.selectedParishLat;
                this.place.coordinates.lng = this.selectedParishLng;
            },
            async getUsersName(search) {
                await getUsers({
                    populate: 'pictures, role, parkingLots, picture',
                    sort: ['name:asc'],
                    'filters[$or]': [
                        {
                            name: {
                                $containsi: search,
                            },
                        },
                        {
                            email: {
                                $containsi: search,
                            },
                        },
                        {
                            parkingLots: {
                                name: {
                                    $containsi: search,
                                },
                            },
                        },
                    ],
                })
                    .then((response) => {
                        console.log(response.data.data);
                        this.managers = response.data.data;
                    })
                    .finally(() => {
                        this.isLoadingTable = false;
                    });
            },

            deletePark() {
                this.$store
                    .dispatch('app-park/deletePark', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'ParkList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                        console.log(error);
                    });
            },

            searchUsers() {
                this.isLoadingTable = true;

                this.getUsersName(this.searchUser);
                setTimeout(() => {
                    this.isLoadingTable = false;
                }, 1000);
            },

            getFinalStructure(structure, type, selectedStructure) {
                console.log(structure);
                console.log(type);
                console.log(selectedStructure);
                this.structure = structure;
                this.monitoringType = type;
                this.selectedStructure = selectedStructure;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #01acca;
        border-radius: 10px;
    }

    .v-avatar {
        position: relative;
        .file {
            background-color: #01acca;
            border-radius: 10px;
            padding: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
