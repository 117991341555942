<template>
    <v-tabs class="tabs-container" hide-slider active-class="active-tab">
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'mr-1 sm' : 'mr-6'" v-if="structure.includes('floors')">{{
            $tc('Floors', 2)
        }}</v-tab>
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'mx-2 sm' : 'mx-3'" v-if="structure.includes('sections')">{{
            $tc('Sections', 2)
        }}</v-tab>
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'ml-1 sm' : 'ml-6'">{{ $tc('Spots', 2) }}</v-tab>

        <v-tab-item class="px-2" v-if="structure.includes('floors')">
            <ValidationObserver v-slot="{ handleSubmit }" :disabled="true">
                <v-form class="mt-15" @submit.prevent="handleSubmit(onAddFloor)">
                    <v-row>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Floor')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$tc('Floor')"
                                    hide-details="auto"
                                    required
                                    v-model="floorName"
                                    name="floorName"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$t('Quantity')" rules="min_value:0|required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$t('Quantity')"
                                    hide-details="auto"
                                    type="number"
                                    required
                                    v-model="floorQuantity"
                                    name="floorQuantity"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="2" class="mt-1 mx-3">
                            <v-row class="mt-0">
                                <v-btn color="primary" type="submit" block :min-height="50" :disabled="disabled">
                                    <span>{{ $t('Buttons.Add') }}</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-8" style="padding-bottom: 100px">
                <v-col cols="12" md="6" lg="5" v-for="(floor, index) in createdFloors" :key="index">
                    <ValidationObserver :ref="floor.name">
                        <v-sheet>
                            <v-sheet class="px-3 py-5 has-radius" :elevation="6">
                                <v-row class="px-2">
                                    <v-col cols="4" md="4" class="d-flex align-center">
                                        <span
                                            class="black--text text-body-2 font-weight-medium"
                                            v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                            >{{ floor.name }}</span
                                        >

                                        <ValidationProvider
                                            :name="$t('Floor')"
                                            rules="required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field v-model="newFloorName"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" md="4" class="d-flex align-center">
                                        <span
                                            class="black--text text-body-2 font-weight-medium"
                                            v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                            >{{ floor.quantity }} {{ $tc('Spots', floor.quantity) }}</span
                                        >

                                        <ValidationProvider
                                            :name="$t('Quantity')"
                                            rules="min_value:0|required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field type="number" v-model="newFloorQuantity"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4" md="2" offset-md="2" class="d-flex justify-end align-center">
                                        <v-dialog
                                            transition="dialog-top-transition"
                                            max-width="600"
                                            v-if="!editFloor || (editFloor && editFloorIndex != index)">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                    <v-icon>
                                                        {{ mdiTrashCanOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <v-img
                                                    class="mx-auto trashcan"
                                                    width="80"
                                                    src="@/assets/icons/delete.png"></v-img>
                                                <v-card>
                                                    <v-card-text class="text-center">
                                                        <p class="title font-weight-semibold black--text mt-12">
                                                            {{ $t('Alerts.Sure') }}
                                                        </p>
                                                    </v-card-text>
                                                    <v-card-text class="text-center">
                                                        <p class="body-2">
                                                            {{ $t('Alerts.SureDeleteFloor') }}
                                                            <strong class="black--text">{{ floor.name }}</strong>
                                                            ?<br />
                                                            {{ $t('Alerts.Irreversible') }}
                                                        </p>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end mt-4">
                                                        <v-btn
                                                            @click="
                                                                deleteFloor(
                                                                    index,
                                                                    floor.name,
                                                                    floor.id,
                                                                    (dialog.value = false),
                                                                )
                                                            "
                                                            color="error"
                                                            class="px-5"
                                                            >{{ $t('Buttons.Delete') }}</v-btn
                                                        >
                                                        <v-btn text @click="dialog.value = false">{{
                                                            $t('Buttons.Cancel')
                                                        }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                        <v-btn
                                            v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                            elevation="0"
                                            @click="activateEditFloor(floor.name, floor.quantity, index)"
                                            :disabled="disabled"
                                            class="ml-2">
                                            <v-icon color="grey">
                                                {{ mdiFileDocumentEditOutline }}
                                            </v-icon></v-btn
                                        >
                                        <v-btn
                                            v-else-if="editFloor && editFloorIndex == index"
                                            elevation="0"
                                            @click="saveEditFloor(floor.name)">
                                            <v-icon color="grey">
                                                {{ mdiCheck }}
                                            </v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-sheet>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </v-tab-item>

        <v-tab-item class="px-2" v-if="structure.includes('sections')">
            <ValidationObserver v-slot="{ handleSubmit }" :disabled="true">
                <v-form class="mt-15" @submit.prevent="handleSubmit(onAddSection)">
                    <v-row>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Floor')" rules="required" v-slot="{ errors }">
                                <v-select
                                    filled
                                    shaped
                                    :items="createdFloors.length > 0 ? createdFloors : noFloorArray"
                                    :label="$tc('Floor')"
                                    :item-text="createdFloors.length > 0 ? 'name' : 'floor'"
                                    item-value="value"
                                    name="selectedFloor"
                                    v-model="sectionFloor"
                                    required
                                    hide-details="auto"
                                    :disabled="disabled"></v-select>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Section')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$tc('Section')"
                                    hide-details="auto"
                                    required
                                    v-model="sectionName"
                                    name="sectionName"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$t('Quantity')" rules="min_value:0|required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$t('Quantity')"
                                    hide-details="auto"
                                    type="number"
                                    required
                                    v-model="sectionQuantity"
                                    name="sectionQuantity"
                                    :error="spotQuantityError"
                                    :disabled="disabled">
                                </v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                <span v-if="spotQuantityError" class="caption error--text mb-10">{{
                                    $t('spotQuantityError')
                                }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="2" class="mt-1 mx-3">
                            <v-row class="mt-0">
                                <v-btn color="primary" type="submit" block :min-height="50" :disabled="disabled">
                                    <span>{{ $t('Buttons.Add') }}</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-8" style="padding-bottom: 100px">
                <v-col cols="12" md="8" lg="6" v-for="(section, index) in createdSections" :key="index">
                    <ValidationObserver :ref="section.name">
                        <v-sheet>
                            <v-sheet class="px-3 py-5 has-radius" :elevation="6">
                                <v-row class="px-2">
                                    <v-col cols="3" md="3" class="d-flex align-center">
                                        <span class="black--text text-body-2 font-weight-medium">{{
                                            $t(section.floor)
                                        }}</span>
                                    </v-col>
                                    <v-col cols="3" md="3" class="d-flex align-center">
                                        <span
                                            class="black--text text-body-2 font-weight-medium"
                                            v-if="!editSection || (editSection && editSectionIndex != index)"
                                            >{{ $t(section.name) }}</span
                                        >
                                        <ValidationProvider
                                            :name="$t('Section')"
                                            rules="required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field v-model="newSectionName" required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="3" class="d-flex align-center">
                                        <span
                                            class="black--text text-body-2 font-weight-medium"
                                            v-if="!editSection || (editSection && editSectionIndex != index)"
                                            >{{ section.quantity }} {{ $tc('Spots', section.quantity) }}</span
                                        >
                                        <ValidationProvider
                                            :name="$t('Quantity')"
                                            rules="min_value:0|required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field
                                                type="number"
                                                :error="editSpotQuantityError"
                                                v-model="newSectionQuantity"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            <span v-if="editSpotQuantityError" class="caption error--text mb-10">{{
                                                $t('spotQuantityError')
                                            }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="3" class="d-flex justify-end align-center">
                                        <v-dialog
                                            transition="dialog-top-transition"
                                            max-width="600"
                                            v-if="!editSection">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                    <v-icon>
                                                        {{ mdiTrashCanOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <v-img
                                                    class="mx-auto trashcan"
                                                    width="80"
                                                    src="@/assets/icons/delete.png"></v-img>
                                                <v-card>
                                                    <v-card-text class="text-center">
                                                        <p class="title font-weight-semibold black--text mt-12">
                                                            {{ $t('Alerts.Sure') }}
                                                        </p>
                                                    </v-card-text>
                                                    <v-card-text class="text-center">
                                                        <p class="body-2">
                                                            {{ $t('Alerts.SureDeleteSection') }}
                                                            <strong class="black--text">{{ section.name }}</strong>
                                                            ?<br />
                                                            {{ $t('Alerts.Irreversible') }}
                                                        </p>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end mt-4">
                                                        <v-btn
                                                            @click="
                                                                deleteSection(index, section.id, (dialog.value = false))
                                                            "
                                                            color="error"
                                                            class="px-5"
                                                            >{{ $t('Buttons.Delete') }}</v-btn
                                                        >
                                                        <v-btn text @click="dialog.value = false">{{
                                                            $t('Buttons.Cancel')
                                                        }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>

                                        <v-btn
                                            elevation="0"
                                            v-if="!editSection || (editSection && editSectionIndex != index)"
                                            class="ml-2"
                                            @click="
                                                activateEditSection(
                                                    section.name,
                                                    section.quantity,
                                                    index,
                                                    section.floor,
                                                )
                                            "
                                            :disabled="disabled">
                                            <v-icon color="grey">
                                                {{ mdiFileDocumentEditOutline }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn v-else elevation="0" @click="saveEditSection(section.name)">
                                            <v-icon color="grey">
                                                {{ mdiCheck }}
                                            </v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-sheet>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </v-tab-item>

        <v-tab-item>
            <v-sheet class="mt-15">
                <v-row>
                    <v-col cols="10">
                        <v-sheet class="px-3 py-2 has-radius" :elevation="0" style="background-color: #f9f9f9">
                            <v-row class="px-6 has-radius" style="width: 100%">
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Floor') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Section') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{
                                        $tc('SpotNumber')
                                    }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Sensor') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Type') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Actions') }}</span>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-row
                v-if="
                    (structure.includes('floors') && structure.includes('sections')) || structure.includes('sections')
                ">
                <v-col
                    class="spots-container"
                    cols="10"
                    v-for="(floor, floorIndex) in createdSections"
                    :key="floorIndex">
                    <div v-for="(spot, spotsIndex) in floor.spots" :key="spotsIndex">
                        <ValidationObserver :ref="spot.name">
                            <v-sheet class="px-3 mx-2 my-6 py-5 has-radius" :elevation="6">
                                <v-row class="px-6 has-radius" style="width: 100%">
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">{{ spot.floor }}</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">{{
                                            spot.section
                                        }}</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span
                                            class="black--text text-body-1 font-weight-medium"
                                            v-if="!spot.isEditting"
                                            >{{ spot.name }}</span
                                        >
                                        <ValidationProvider
                                            :name="$tc('SpotNumber')"
                                            rules="required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field v-model="spot.name" required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span
                                            class="black--text text-body-1 font-weight-medium"
                                            v-if="!spot.isEditting">
                                            {{ spot.sensor }}
                                        </span>
                                        <ValidationProvider :name="$t('Sensor')" v-slot="{ errors }" v-else>
                                            <v-text-field type="number" v-model="spot.sensor"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">{{
                                            $tc('Individual')
                                        }}</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <v-dialog
                                            transition="dialog-top-transition"
                                            max-width="600"
                                            v-if="!spot.isEditting">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                    <v-icon>
                                                        {{ mdiTrashCanOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <v-img
                                                    class="mx-auto trashcan"
                                                    width="80"
                                                    src="@/assets/icons/delete.png"></v-img>
                                                <v-card>
                                                    <v-card-text class="text-center">
                                                        <p class="title font-weight-semibold black--text mt-12">
                                                            {{ $t('Alerts.Sure') }}
                                                        </p>
                                                    </v-card-text>
                                                    <v-card-text class="text-center">
                                                        <p class="body-2">
                                                            {{ $t('Alerts.SureDeleteSpot') }}
                                                            <strong class="black--text">{{ spot.name }}</strong
                                                            >,
                                                            {{ $t('Section') }}
                                                            <strong class="black--text">{{ spot.section }}</strong
                                                            >,
                                                            {{ $t('Floor') }}
                                                            <strong class="black--text">{{ spot.floor }}</strong>
                                                            ?<br />
                                                            {{ $t('Alerts.Irreversible') }}
                                                        </p>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end mt-4">
                                                        <v-btn
                                                            @click="
                                                                deleteSpot(
                                                                    floor.floor,
                                                                    floor.name,
                                                                    spotsIndex,
                                                                    spot.id,

                                                                    (dialog.value = false),
                                                                )
                                                            "
                                                            color="error"
                                                            class="px-5"
                                                            >{{ $t('Buttons.Delete') }}</v-btn
                                                        >
                                                        <v-btn text @click="dialog.value = false">{{
                                                            $t('Buttons.Cancel')
                                                        }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>

                                        <v-btn
                                            elevation="0"
                                            v-if="!spot.isEditting"
                                            class="ml-2"
                                            @click="activateEditSpot(spot.floor, spot.section, spotsIndex)"
                                            :disabled="disabled">
                                            <v-icon color="grey">
                                                {{ mdiFileDocumentEditOutline }}
                                            </v-icon>
                                        </v-btn>

                                        <v-btn
                                            v-else
                                            elevation="0"
                                            @click="saveEditSpot(spot.floor, spot.section, spotsIndex)">
                                            <v-icon color="grey">
                                                {{ mdiCheck }}
                                            </v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </ValidationObserver>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else-if="structure.includes('floors')">
                <v-col class="spots-container" cols="10" v-for="(floor, floorIndex) in createdFloors" :key="floorIndex">
                    <div v-for="(spot, spotsIndex) in floor.spots" :key="spotsIndex">
                        <ValidationObserver :ref="spot.name">
                            <v-sheet class="px-3 mx-2 my-6 py-5 has-radius" :elevation="6">
                                <v-row class="px-6 has-radius" style="width: 100%">
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">{{ spot.floor }}</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">-</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span
                                            class="black--text text-body-1 font-weight-medium"
                                            v-if="!spot.isEditting"
                                            >{{ spot.name }}</span
                                        >
                                        <ValidationProvider
                                            :name="$tc('SpotNumber')"
                                            rules="required"
                                            v-slot="{ errors }"
                                            v-else>
                                            <v-text-field v-model="spot.name" required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span
                                            class="black--text text-body-1 font-weight-medium"
                                            v-if="!spot.isEditting">
                                            {{ spot.sensor }}
                                        </span>
                                        <ValidationProvider :name="$t('Sensor')" v-slot="{ errors }" v-else>
                                            <v-text-field type="number" v-model="spot.sensor"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <span class="black--text text-body-1 font-weight-medium">{{
                                            $tc('Type')
                                        }}</span>
                                    </v-col>
                                    <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                        <v-dialog
                                            transition="dialog-top-transition"
                                            max-width="600"
                                            v-if="!spot.isEditting">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                    <v-icon>
                                                        {{ mdiTrashCanOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <v-img
                                                    class="mx-auto trashcan"
                                                    width="80"
                                                    src="@/assets/icons/delete.png"></v-img>
                                                <v-card>
                                                    <v-card-text class="text-center">
                                                        <p class="title font-weight-semibold black--text mt-12">
                                                            {{ $t('Alerts.Sure') }}
                                                        </p>
                                                    </v-card-text>
                                                    <v-card-text class="text-center">
                                                        <p class="body-2">
                                                            {{ $t('Alerts.SureDeleteSpot') }}
                                                            <strong class="black--text">{{ spot.name }}</strong
                                                            >,

                                                            {{ $t('Floor') }}
                                                            <strong class="black--text">{{ spot.floor }}</strong>
                                                            ?<br />
                                                            {{ $t('Alerts.Irreversible') }}
                                                        </p>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end mt-4">
                                                        <v-btn
                                                            @click="
                                                                deleteSpot(
                                                                    spot.floor,
                                                                    null,
                                                                    spotsIndex,
                                                                    spot.id,
                                                                    (dialog.value = false),
                                                                )
                                                            "
                                                            color="error"
                                                            class="px-5"
                                                            >{{ $t('Buttons.Delete') }}</v-btn
                                                        >
                                                        <v-btn text @click="dialog.value = false">{{
                                                            $t('Buttons.Cancel')
                                                        }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>

                                        <v-btn
                                            elevation="0"
                                            class="ml-2"
                                            v-if="!spot.isEditting"
                                            @click="activateEditSpot(spot.floor, spot.section, spotsIndex)"
                                            :disabled="disabled">
                                            <v-icon color="grey">
                                                {{ mdiFileDocumentEditOutline }}
                                            </v-icon>
                                        </v-btn>

                                        <v-btn
                                            v-else
                                            elevation="0"
                                            @click="saveEditSpot(spot.floor, spot.section, spotsIndex)">
                                            <v-icon color="grey">
                                                {{ mdiCheck }}
                                            </v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </ValidationObserver>
                    </div>
                </v-col>
            </v-row>
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mdiFileDocumentEditOutline, mdiTrashCanOutline, mdiCheck } from '@mdi/js';
    import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
    import { deleteSection } from '@/api/sections';
    import { deleteLots } from '@/api/lots';
    export default {
        props: {
            structure: {
                type: Array,
                required: true,
            },

            createdStructure: {
                type: Array,
                required: false,
            },

            disabled: {
                type: Boolean,
                required: false,
            },
        },

        components: {
            ValidationProvider,
            ValidationObserver,
            PerfectScrollbar,
        },

        setup() {
            return {
                mdiFileDocumentEditOutline,
                mdiTrashCanOutline,
                mdiCheck,
            };
        },

        data() {
            return {
                floorName: '',
                floorQuantity: 0,
                createdFloors: [],
                createdSections: [],
                sectionName: '',
                sectionQuantity: 0,
                sectionFloor: null,
                noFloorArray: [],
                spotQuantityError: false,
                editFloor: false,
                savedFloorName: null,
                editSection: false,
                editSpotQuantityError: false,
                newSectionName: null,
                newSectionQuantity: 0,
                editSpot: false,
                newFloorQuantity: null,
                newFloorName: null,
                editFloorIndex: null,
                editSectionIndex: null,
                sectionToEditFloorName: null,
            };
        },

        methods: {
            onAddFloor() {
                //verify if name exist in object array
                const floorExist = this.createdFloors.find((floor) => floor.name === this.floorName);

                if (floorExist) {
                    console.log(this.floorQuantity);
                    floorExist.quantity = parseInt(floorExist.quantity) + parseInt(this.floorQuantity);

                    if (!this.structure.includes('sections')) {
                        floorExist.floorTotalCapacity =
                            parseInt(floorExist.floorTotalCapacity) + parseInt(this.floorQuantity);
                        floorExist.spots = [];
                        for (let i = 0; i < floorExist.quantity; i++) {
                            floorExist.spots.push({
                                floor: floorExist.name,
                                name: i + 1,
                                sensor: '-',
                                type: 'null',
                                isEditting: false,
                            });
                        }
                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                } else {
                    if (this.structure.includes('sections')) {
                        this.createdFloors.push({
                            name: this.floorName,
                            quantity: this.floorQuantity,
                        });
                    } else {
                        this.createdFloors.push({
                            name: this.floorName,
                            quantity: this.floorQuantity,
                            spots: [],
                            floorTotalCapacity: this.floorQuantity,
                        });

                        for (let i = 0; i < this.createdFloors.length; i++) {
                            if (i == this.createdFloors.length - 1) {
                                for (let x = 0; x < this.createdFloors[i].quantity; x++) {
                                    this.createdFloors[i].spots.push({
                                        floor: this.createdFloors[i].name,
                                        name: x + 1,
                                        sensor: '-',
                                        type: 'null',
                                        isEditting: false,
                                    });
                                }
                            }
                        }

                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                }

                this.floorQuantity = 0;

                console.log(this.createdFloors);
            },
            onAddSection() {
                console.log(this.sectionFloor);

                // verify if array includes floor name
                const floorExist = this.createdFloors.find((floor) => floor.name == this.sectionFloor);
                console.log(floorExist);

                if (floorExist) {
                    if (this.createdSections.length > 0) {
                        //verify if section name and floor exist in object array
                        const sectionExist = this.createdSections.find(
                            (section) => section.name === this.sectionName && section.floor === this.sectionFloor,
                        );
                        if (sectionExist) {
                            let verifyTotalSpotsCreated = 0;
                            this.createdSections.map((section) => {
                                if (section.floor === this.sectionFloor) {
                                    verifyTotalSpotsCreated =
                                        parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                                }
                            });

                            if (
                                verifyTotalSpotsCreated + parseInt(this.sectionQuantity) >
                                    parseInt(floorExist.quantity) &&
                                floorExist.showOnEdit
                            ) {
                                this.spotQuantityError = true;
                            } else {
                                this.spotQuantityError = false;
                                sectionExist.quantity =
                                    parseInt(sectionExist.quantity) + parseInt(this.sectionQuantity);
                                this.sectionQuantity = 0;

                                sectionExist.spots = [];

                                for (let i = 0; i < sectionExist.quantity; i++) {
                                    sectionExist.spots.push({
                                        floor: sectionExist.floor,
                                        section: sectionExist.name,
                                        name: i + 1,
                                        sensor: '-',
                                        type: 'null',
                                        isEditting: false,
                                    });
                                }
                            }
                        } else {
                            let verifyTotalSpotsCreated = 0;
                            this.createdSections.map((section) => {
                                if (section.floor === this.sectionFloor) {
                                    verifyTotalSpotsCreated =
                                        parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                                }
                            });

                            if (
                                verifyTotalSpotsCreated + parseInt(this.sectionQuantity) >
                                    parseInt(floorExist.quantity) &&
                                floorExist.showOnEdit
                            ) {
                                this.spotQuantityError = true;
                            } else {
                                this.spotQuantityError = false;
                                this.createdSections.push({
                                    floor: this.sectionFloor,
                                    name: this.sectionName,
                                    quantity: this.sectionQuantity,
                                    spots: [],
                                    isEditting: false,
                                    floorTotalCapacity: parseInt(floorExist.quantity),
                                });

                                this.sectionQuantity = 0;

                                for (let i = 0; i < this.createdSections.length; i++) {
                                    if (i == this.createdSections.length - 1) {
                                        for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                            this.createdSections[i].spots.push({
                                                floor: this.createdSections[i].floor,
                                                section: this.createdSections[i].name,
                                                name: x + 1,
                                                sensor: '-',
                                                type: 'null',
                                                isEditting: false,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        let verifyTotalSpotsCreated = 0;
                        this.createdSections.map((section) => {
                            if (section.floor === this.sectionFloor) {
                                verifyTotalSpotsCreated =
                                    parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                            }
                        });

                        if (verifyTotalSpotsCreated + parseInt(this.sectionQuantity) > parseInt(floorExist.quantity)) {
                            this.spotQuantityError = true;
                        } else {
                            this.spotQuantityError = false;
                            this.createdSections.push({
                                floor: this.sectionFloor,
                                name: this.sectionName,
                                quantity: this.sectionQuantity,
                                spots: [],
                                floorTotalCapacity: parseInt(floorExist.quantity),
                            });

                            this.sectionQuantity = 0;

                            for (let i = 0; i < this.createdSections[0].quantity; i++) {
                                this.createdSections[0].spots.push({
                                    floor: this.createdSections[0].floor,
                                    section: this.createdSections[0].name,
                                    name: i + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        }
                    }
                } else {
                    if (this.createdSections.length > 0) {
                        //verify if section name and floor exist in object array
                        const sectionExist = this.createdSections.find((section) => section.name === this.sectionName);
                        if (sectionExist) {
                            sectionExist.quantity = parseInt(sectionExist.quantity) + parseInt(this.sectionQuantity);
                            this.sectionQuantity = 0;

                            sectionExist.spots = [];

                            for (let i = 0; i < sectionExist.quantity; i++) {
                                sectionExist.spots.push({
                                    floor: sectionExist.floor,
                                    section: sectionExist.name,
                                    name: i + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        } else {
                            this.createdSections.push({
                                floor: this.sectionFloor,
                                name: this.sectionName,
                                quantity: this.sectionQuantity,
                                spots: [],
                                /*    floorTotalCapacity: parseInt(floorTotalCapacity) + parseInt(this.sectionQuantity), */
                            });

                            for (let i = 0; i < this.createdSections.length; i++) {
                                if (i == this.createdSections.length - 1) {
                                    for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                        this.createdSections[i].spots.push({
                                            floor: this.createdSections[i].floor,
                                            section: this.createdSections[i].name,
                                            name: x + 1,
                                            sensor: '-',
                                            type: 'null',
                                            isEditting: false,
                                        });
                                    }
                                }
                            }

                            this.sectionQuantity = 0;
                        }
                    } else {
                        this.createdSections.push({
                            floor: this.sectionFloor,
                            name: this.sectionName,
                            quantity: this.sectionQuantity,
                            spots: [],
                            floorTotalCapacity: parseInt(this.sectionQuantity),
                        });

                        for (let i = 0; i < this.createdSections.length; i++) {
                            for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                this.createdSections[i].spots.push({
                                    floor: this.createdSections[i].floor,
                                    section: this.createdSections[i].name,
                                    name: x + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        }

                        this.sectionQuantity = 0;
                    }
                }
                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
            },

            deleteFloor(index, name, id) {
                console.log(id);
                console.log(this.createdFloors);

                if (this.$route.params.id != 'add') {
                    deleteSection(id)
                        .then((response) => {
                            console.log(response);
                            this.createdFloors.splice(index, 1);

                            if (this.createdSections.length > 0) {
                                let createdSectionsClone = this.createdSections.filter(
                                    (section) => section.floor !== name,
                                );
                                this.createdSections = createdSectionsClone;
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            } else this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.createdFloors.splice(index, 1);
                    if (this.createdSections.length > 0) {
                        let createdSectionsClone = this.createdSections.filter((section) => section.floor !== name);
                        this.createdSections = createdSectionsClone;
                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                }
            },

            deleteSection(index, id) {
                if (this.$route.params.id != 'add') {
                    deleteSection(id)
                        .then((response) => {
                            console.log(response);
                            this.createdSections.splice(index, 1);

                            this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.createdSections.splice(index, 1);
                    this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                }
            },
            activateEditFloor(name, quantity, index) {
                this.savedFloorName = name;
                this.newFloorQuantity = quantity;
                this.newFloorName = name;
                this.editFloor = true;
                this.editFloorIndex = index;
            },

            async saveEditFloor() {
                const isValid = await this.$refs[this.savedFloorName][0].validate();
                console.log(isValid);
                if (isValid) {
                    if (this.structure.includes('sections')) {
                        this.createdFloors.map((floor, index) => {
                            if (floor.name == this.savedFloorName) {
                                if (this.newFloorName != this.savedFloorName) {
                                    this.createdFloors[index].name = this.newFloorName;
                                }

                                this.createdFloors[index].quantity = this.newFloorQuantity;
                            }
                        });

                        this.createdSections.map((section, index) => {
                            if (section.floor == this.savedFloorName) {
                                this.createdSections[index].floor = this.newFloorName;
                                this.createdSections[index].floorTotalCapacity = this.newFloorQuantity;
                            }
                        });

                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else {
                        this.createdFloors.map((floor, index) => {
                            if (floor.name == this.savedFloorName) {
                                if (this.newFloorName != this.savedFloorName) {
                                    this.createdFloors[index].name = this.newFloorName;
                                }

                                console.log(this.newFloorQuantity);
                                this.createdFloors[index].quantity = this.newFloorQuantity;

                                if (this.createdFloors[index].quantity > this.createdFloors[index].spots.length) {
                                    let difference =
                                        this.createdFloors[index].quantity - this.createdFloors[index].spots.length;

                                    for (let i = 0; i < difference; i++) {
                                        this.createdFloors[index].spots.push({
                                            floor: this.createdFloors[index].name,
                                            section: '-',
                                            name: this.createdFloors[index].spots.length + 1,
                                            sensor: '-',
                                            type: 'null',
                                        });
                                    }
                                } else if (
                                    this.createdFloors[index].quantity < this.createdFloors[index].spots.length
                                ) {
                                    let difference =
                                        this.createdFloors[index].spots.length - this.createdFloors[index].quantity;

                                    let lotsToRemove = this.createdFloors[index].spots.slice(-difference);

                                    lotsToRemove.map(async (lot) => {
                                        if (lot.id && lot.id != undefined) {
                                            await deleteLots(lot.id)
                                                .then((response) => {
                                                    console.log(response);
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    });

                                    console.log(lotsToRemove);

                                    this.createdFloors[index].spots.splice(
                                        this.createdFloors[index].spots.length - difference,
                                        difference,
                                    );
                                }
                            }

                            console.log(this.createdFloors);
                        });

                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }

                    this.editFloor = false;
                    this.editFloorIndex = null;
                }
            },

            activateEditSection(name, quantity, index, floor) {
                this.savedSectionName = name;
                this.newSectionName = name;
                this.newSectionQuantity = quantity;
                this.editSection = true;
                this.editSectionIndex = index;
                this.sectionToEditFloorName = floor;
            },

            async saveEditSection() {
                const isValid = await this.$refs[this.savedSectionName][0].validate();
                console.log(isValid);
                if (isValid) {
                    if (this.createdSections.length > 0) {
                        this.createdSections.map((section, index) => {
                            if (section.name == this.savedSectionName && section.floor == this.sectionToEditFloorName) {
                                const floor = this.createdFloors.find((floor) => floor.name === section.floor);

                                console.log(floor);

                                if (floor) {
                                    console.log(floor);
                                    console.log(this.newSectionQuantity);
                                    if (this.newSectionQuantity > parseInt(floor.quantity) && floor.showOnEdit) {
                                        this.editSpotQuantityError = true;
                                    } else {
                                        this.editSpotQuantityError = false;
                                        this.editSection = false;

                                        if (this.newSectionName != this.savedSectionName) {
                                            this.createdSections[index].name = this.newSectionName;
                                        }

                                        this.createdSections[index].quantity = this.newSectionQuantity;

                                        if (
                                            this.createdSections[index].quantity >
                                            this.createdSections[index].spots.length
                                        ) {
                                            let difference =
                                                this.createdSections[index].quantity -
                                                this.createdSections[index].spots.length;

                                            for (let i = 0; i < difference; i++) {
                                                this.createdSections[index].spots.push({
                                                    floor: this.createdSections[index].floor,
                                                    section: this.createdSections[index].name,
                                                    name: this.createdSections[index].spots.length + 1,
                                                    sensor: '-',
                                                    type: 'null',
                                                });
                                            }
                                        } else if (
                                            this.createdSections[index].quantity <
                                            this.createdSections[index].spots.length
                                        ) {
                                            let difference =
                                                this.createdSections[index].spots.length -
                                                this.createdSections[index].quantity;

                                            let lotsToRemove = this.createdSections[index].spots.slice(-difference);

                                            lotsToRemove.map(async (lot) => {
                                                if (lot.id && lot.id != undefined) {
                                                    await deleteLots(lot.id)
                                                        .then((response) => {
                                                            console.log(response);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }
                                            });

                                            this.createdSections[index].spots.splice(
                                                this.createdSections[index].spots.length - difference,
                                                difference,
                                            );
                                        }

                                        this.editSection = false;
                                        this.editSectionIndex = null;
                                    }
                                } else {
                                    if (this.newSectionName == '' || this.newSectionName == null) {
                                        this.editSection = true;
                                    } else {
                                        if (this.newSectionName != this.savedSectionName) {
                                            this.createdSections[index].name = this.newSectionName;
                                        }

                                        this.createdSections[index].quantity = this.newSectionQuantity;

                                        if (
                                            this.createdSections[index].quantity >
                                            this.createdSections[index].spots.length
                                        ) {
                                            let difference =
                                                this.createdSections[index].quantity -
                                                this.createdSections[index].spots.length;

                                            for (let i = 0; i < difference; i++) {
                                                this.createdSections[index].spots.push({
                                                    floor: this.createdSections[index].floor,
                                                    section: this.createdSections[index].name,
                                                    name: this.createdSections[index].spots.length + 1,
                                                    sensor: '-',
                                                    type: 'null',
                                                });
                                            }
                                        } else if (
                                            this.createdSections[index].quantity <
                                            this.createdSections[index].spots.length
                                        ) {
                                            let difference =
                                                this.createdSections[index].spots.length -
                                                this.createdSections[index].quantity;

                                            console.log(this.createdSections[index].spots.length);
                                            console.log(this.createdSections[index].quantity);

                                            let lotsToRemove = this.createdSections[index].spots.slice(-difference);

                                            lotsToRemove.map(async (lot) => {
                                                if (lot.id && lot.id != undefined) {
                                                    await deleteLots(lot.id)
                                                        .then((response) => {
                                                            console.log(response);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }
                                            });

                                            this.createdSections[index].spots.splice(
                                                this.createdSections[index].spots.length - difference,
                                                difference,
                                            );
                                        }
                                        this.editSection = false;
                                        this.editSectionIndex = null;
                                    }
                                }
                            }
                        });

                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    }
                }
            },

            async deleteSpot(floorName, sectionName, spotIndex, lotId) {
                console.log(lotId);

                if (lotId && lotId != undefined) {
                    await deleteLots(lotId)
                        .then((response) => {
                            console.log(response);
                            if (this.structure.includes('sections')) {
                                this.createdSections.map((section, index) => {
                                    if (section.floor == floorName && section.name == sectionName) {
                                        section.spots.splice(spotIndex, 1);
                                        section.quantity = parseInt(section.quantity) - 1;
                                    }
                                });
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            } else {
                                this.createdFloors.map((floor, index) => {
                                    if (floor.name == floorName) {
                                        floor.spots.splice(spotIndex, 1);
                                        floor.quantity = parseInt(floor.quantity) - 1;
                                    }
                                });
                                this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    if (this.structure.includes('sections')) {
                        this.createdSections.map((section, index) => {
                            if (section.floor == floorName && section.name == sectionName) {
                                section.spots.splice(spotIndex, 1);
                                section.quantity = parseInt(section.quantity) - 1;
                            }
                        });
                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else {
                        this.createdFloors.map((floor, index) => {
                            if (floor.name == floorName) {
                                floor.spots.splice(spotIndex, 1);
                                floor.quantity = parseInt(floor.quantity) - 1;
                            }
                        });
                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                }
            },

            activateEditSpot(floorName, sectionName, spotIndex) {
                if (this.structure.includes('sections')) {
                    this.createdSections.map((section, index) => {
                        if (section.floor == floorName && section.name == sectionName) {
                            section.spots[spotIndex].isEditting = true;
                        }
                    });
                } else {
                    this.createdFloors.map((floor, index) => {
                        if (floor.name == floorName) {
                            floor.spots[spotIndex].isEditting = true;
                        }
                    });
                }
            },

            saveEditSpot(floorName, sectionName, spotIndex) {
                let isValid;
                if (this.structure.includes('sections')) {
                    this.createdSections.map(async (section, index) => {
                        if (section.floor == floorName && section.name == sectionName) {
                            isValid = await this.$refs[section.spots[spotIndex].name][0].validate();

                            if (isValid) {
                                section.spots[spotIndex].isEditting = false;
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            }
                        }
                    });
                } else {
                    this.createdFloors.map(async (floor, index) => {
                        if (floor.name == floorName) {
                            isValid = await this.$refs[floor.spots[spotIndex].name][0].validate();

                            if (isValid) {
                                floor.spots[spotIndex].isEditting = false;
                                this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                            }
                        }
                    });
                }
            },
        },

        created() {
            this.noFloorArray.push({
                floor: this.$tc('Floor', 0) + ' ' + 0,
                value: this.$tc('Floor', 0) + ' ' + 0,
            });

            console.log(this.createdStructure);

            let includesFloor = this.createdStructure.filter((floor) => floor.type == 'floor');
            console.log(includesFloor);

            if (this.createdStructure.length > 0) {
                this.createdStructure.forEach((element, index) => {
                    if (element.type == 'floor') {
                        console.log(element);
                        if (element.children.length > 0) {
                            this.createdFloors.push({
                                name: element.name,
                                quantity: element.capacity,
                                showOnEdit: element.showOnEdit,
                                id: element.id,
                            });

                            element.children.forEach((section) => {
                                this.createdSections.push({
                                    floor: element.name,
                                    name: section.name,
                                    quantity: section.capacity,
                                    spots: [],
                                    isEditting: false,
                                    floorTotalCapacity: element.capacity,
                                    id: section.id,
                                    floorId: element.id,
                                });

                                console.log(this.createdSections);

                                let foundSection = this.createdSections.find((s, index) => s.id == section.id);

                                let foundIndex = this.createdSections.indexOf(foundSection);
                                console.log(foundSection);
                                if (foundSection) {
                                    console.log(element);
                                    console.log(foundSection.id);
                                    element.children.map((section) => {
                                        console.log(section);
                                        section.lots.forEach((lot) => {
                                            if (section.id == foundSection.id) {
                                                this.createdSections[foundIndex].spots.push({
                                                    floor: foundSection.floor,
                                                    section: foundSection.name,
                                                    name: lot.name,
                                                    sensor: lot.sensorId,
                                                    type: 'null',
                                                    isEditting: false,
                                                    id: lot.id,
                                                });
                                            }
                                        });
                                    });
                                }
                            });
                        } else if (element.children.length == 0 && element.type == 'floor') {
                            this.createdFloors.push({
                                name: element.name,
                                quantity: element.capacity,
                                spots: [],
                                floorTotalCapacity: element.capacity,
                                id: element.id,
                            });

                            console.log(element);
                            console.log(this.createdFloors);
                            console.log(index);

                            element.lots.forEach((lot) => {
                                this.createdFloors[index].spots.push({
                                    floor: element.name,
                                    name: lot.name,
                                    sensor: lot.sensorId,
                                    type: 'null',
                                    isEditting: false,
                                    id: lot.id,
                                });
                            });
                        }
                    } else {
                        if (includesFloor.length == 0) {
                            if (element.type == 'section') {
                                console.log(element);
                                let foundSection = this.createdSections.find(
                                    (section, index) => section.id == element.id,
                                );

                                if (foundSection) {
                                    element.lots.forEach((lot) => {
                                        foundSection.spots.push({
                                            floor: foundSection.floor,
                                            section: foundSection.name,
                                            name: lot.name,
                                            sensor: lot.sensorId,
                                            type: 'null',
                                            isEditting: false,
                                            id: lot.id,
                                        });
                                    });
                                }
                            }
                        }
                    }
                });
            }
        },
    };
</script>

<style lang="scss" scoped>
    .active-tab {
        background-color: $primary !important;
        color: white !important;
    }

    .v-tab.v-tab {
        min-width: 200px;
        max-width: 200px;
        background-color: #e1e1e1;
        border-radius: 10px;
    }

    .sm {
        min-width: 100px !important;
        max-width: 50px !important;
    }

    .v-tab.v-tab .v-slide-group__prev,
    .v-tab.v-tab .v-slide-group__next {
        display: none !important;
    }

    .has-radius {
        border-radius: 16px;
    }

    .spots-container:last-child {
        padding-bottom: 100px;
    }
</style>
